exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-book-a-demo-tsx": () => import("./../../../src/pages/book-a-demo.tsx" /* webpackChunkName: "component---src-pages-book-a-demo-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-hospitality-management-tsx": () => import("./../../../src/pages/hospitality-management.tsx" /* webpackChunkName: "component---src-pages-hospitality-management-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-media-kit-tsx": () => import("./../../../src/pages/media-kit.tsx" /* webpackChunkName: "component---src-pages-media-kit-tsx" */),
  "component---src-pages-mortage-tsx": () => import("./../../../src/pages/mortage.tsx" /* webpackChunkName: "component---src-pages-mortage-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-our-company-tsx": () => import("./../../../src/pages/our-company.tsx" /* webpackChunkName: "component---src-pages-our-company-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-partnership-tsx": () => import("./../../../src/pages/partnership.tsx" /* webpackChunkName: "component---src-pages-partnership-tsx" */),
  "component---src-pages-pocket-resource-tsx": () => import("./../../../src/pages/pocket-resource.tsx" /* webpackChunkName: "component---src-pages-pocket-resource-tsx" */),
  "component---src-pages-rap-bot-tsx": () => import("./../../../src/pages/rap-bot.tsx" /* webpackChunkName: "component---src-pages-rap-bot-tsx" */),
  "component---src-pages-rap-disclaimer-tsx": () => import("./../../../src/pages/rap-disclaimer.tsx" /* webpackChunkName: "component---src-pages-rap-disclaimer-tsx" */),
  "component---src-pages-rap-flow-tsx": () => import("./../../../src/pages/rap-flow.tsx" /* webpackChunkName: "component---src-pages-rap-flow-tsx" */),
  "component---src-pages-rap-privacy-policy-tsx": () => import("./../../../src/pages/rap-privacy-policy.tsx" /* webpackChunkName: "component---src-pages-rap-privacy-policy-tsx" */),
  "component---src-pages-rap-terms-of-use-tsx": () => import("./../../../src/pages/rap-terms-of-use.tsx" /* webpackChunkName: "component---src-pages-rap-terms-of-use-tsx" */),
  "component---src-pages-read-blog-tsx": () => import("./../../../src/pages/read-blog.tsx" /* webpackChunkName: "component---src-pages-read-blog-tsx" */),
  "component---src-pages-rpa-calculator-tsx": () => import("./../../../src/pages/rpa-calculator.tsx" /* webpackChunkName: "component---src-pages-rpa-calculator-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-use-case-studies-tsx": () => import("./../../../src/pages/use-case-studies.tsx" /* webpackChunkName: "component---src-pages-use-case-studies-tsx" */),
  "component---src-pages-use-case-view-tsx": () => import("./../../../src/pages/use-case-view.tsx" /* webpackChunkName: "component---src-pages-use-case-view-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-webinar-tsx": () => import("./../../../src/pages/webinar.tsx" /* webpackChunkName: "component---src-pages-webinar-tsx" */),
  "component---src-pages-work-with-us-tsx": () => import("./../../../src/pages/work-with-us.tsx" /* webpackChunkName: "component---src-pages-work-with-us-tsx" */)
}

